$icon-font-path: '../fonts/';

$font-family-sans-serif:      'Lilita One', sans-serif;
$headings-font-family:      $font-family-sans-serif !default;
$headings-font-weight:      700 !default;

$nav-link-hover-bg:         #9d272c;

$primary_color:             #C10E1A;
$secondary_color:           #761a1a;//#9d272c;
$tertiary_color:            #761a1a;
$quatro_color:            #9d272c;


//$border-radius-base:        0px !default;
//$border-radius-large:       0px !default;
//$border-radius-small:       0px !default;