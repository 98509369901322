@import 'variables';
@import 'webfonts';
//@import 'fonts';

//@import '../plugins/custom-scrollbar/css/jquery.mCustomScrollbar.scss';

//@import '../plugins/lightbox/dist/css/lightbox.scss';
//@import '../plugins/leaflet/leaflet.scss';
@import 'bootstrap/stylesheets/_bootstrap';

body {margin:0px;
     font-family: "Roboto";
     margin-top : 50px;
    
}

.display-none { display: none;}

.btn-primary {
    @extend .anim;
        background-color:#c10e1a;
        border-color:#c10e1a;
        font-weight: bold;
        
        &:hover, &:focus  {
            background-color:#c10e1a;
            border-color:#c10e1a;
            opacity:0.8;
        }

}

.m-b-25 { margin-bottom:25px;}
.p-b-20 { padding-bottom:20px;}
.p-b { padding-bottom:20px;}
/* HEADER */
#wrapper {
  width: 100%;
}

video {height:100vh; width:auto;}

#page-wrapper {
  min-height: 100vh;
  background-color: white;
}


#home {height: 50vh;}
.header-title {font-family: "Lilita One"; font-size: 24px; color:#FFF; margin-top:10px;}
.royalSlider {
  width: 100%;
  height: 300px;
}

.navbar {min-height:auto!important;}
.square {
  position: relative;
  padding: 0px;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}


.text-primary {color : #e3941d;}
textarea {resize:none;}
.bg-white {background: #FFF;}

.tile-text {
    font-family: "Lilita One";
    display:inline-block;
    width:100%;
    color:#FFF;
    font-size: 5.5vw; letter-spacing: 1px; line-height: 5.8vw;
    text-align: center;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}


.groupes, .privatisation, .contacts {
    
    text-align: center;
    h2 {color : #F1CA13; font-size: 8vw; margin-top:35px;}
    p { color:#000; font-family: "Roboto"; font-size: 16px; line-height: 26px;}
}

.infos { 
    text-align: center;
    padding-top : 0px;
    h2 {color : #F1CA13; font-size: 8vw; margin-top:35px;}
    p { color:#000; font-family: "Roboto"; font-size: 16px; line-height: 26px; margin-bottom:0px;}
    
}


#header {
    .navbar-fixed-top {
        background-color:$primary_color;
        border:none;
    }

    .navbar-header {
        float:none;

        .navbar-toggle:not(.btn-collapse-remove-infos) {
            border:none;

            span {
                background-color:white;
            }
            &:hover, &:focus {
                background-color:$tertiary_color;
            }
        }

        .btn-collapse-remove-infos {
            display:none;
            position:absolute;
            top:0; right:0;
            border:none;
            margin-top:5px;
            margin-bottom:0;

            span {
                background-color: $primary_color;
                color:white;
                font-size:22px;
            }

            &:hover, &:focus {
                background-color:$primary_color;
            }
        }

        .navbar-brand {
            padding:5px 5px 5px 15px;

            img {
                height:40px;
            }
        }
    }

    .sidebar {
        background-color:$primary_color;
        margin-top:0;

        .sidebar-nav.navbar-collapse {
            padding-left: 0;
            padding-right: 0;
            border:none;
            box-shadow:none;
            /*max-height: 100%;*/
            max-height: calc(100vh - 50px);
        }

        #side-menu, #infos-menu {
            
            > li {
                position:relative;

                > a {
                    font-family:"Lilita One";
                    color:white;
                    padding: 5px 15px;
                    font-size:22px;
                    display: flex;
                    align-items: center;
                    line-height:26px;
                    letter-spacing: 1px;
                    span {
                        font-size:30px;
                        margin-right:10px;
                    }

                    &:hover, &:focus {
                        background-color:$tertiary_color;
                    }
                }

                .btn-collapse {
                    line-height:40px;
                    height:42px;
                    width:42px;
                    text-align:center;
                    position:absolute;
                    top:0; right:0;
                    cursor: pointer;
                    color:white;
                }

                & > ul {
                    list-style-type: none;
                    padding:0 15px 0 55px;

                    > li {
                        border-bottom:1px solid $secondary_color;

                        > a {
                            line-height:34px;
                            min-height:40px;
                            font-size:20px;
                            color:white;
                            text-decoration:none;
                        }

                        &:last-child {
                            border:none;
                        }
                    }
                }

                &.active {
                    background-color:$tertiary_color;
                    > a {
                        background-color:$quatro_color;
                    }
                }

                &#events {
                    .collapse {
                        padding:15px;
                    }
                }
            }
        }
    }

    .nav-icone-fixed {
        position:fixed;
        top:50px;
        z-index: 100;
        background-color:$secondary_color;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    #lang {
        top:19px;
        right:20px;
        transition: top 0.35s ease-in-out;

        img {
            width:20px;
            margin:5px;
        }

        &:hover {
            top:50px;
            transition: top 0.35s ease-in-out;
        }
    }

    .menu-home-cond { display:none; }
    .menu-home-hide { display:none; }



    .menu-home {
        padding:30px 15px;

        .menu-home-item {
            text-align:center;
            margin-bottom:50px;
            a {
                color:white;
                text-decoration:none;

                span {
                    display:block;
                    font-size:80px;
                    margin-bottom:10px;
                }
            }
        }

        #collapse-events {
            display:block !important;

        }
    }

}

#home {
    .intro {
        width:100%;
        color : #FFF;
        font-size: 11vw;
        display: flex;
        padding-top : 15vh;
        align-items: center;
        justify-content: center;
        line-height: 13vw;
        height: 25vh;
        font-family:"Lilita One";
        text-shadow: 4px 4px 6px #000000;
    }
}

#visit { font-family: "Lilita One";}
#content {
    padding-top:50px;
    margin:15px -15px 0 -15px;

    &.no-margin-top {
        margin-top:0;
    }

    h1 {
        color:$primary_color;
        font-size:24px;
        margin-bottom:20px;
    }

    .content-image {
        margin-top:20px;

        img { width:100%; }
    }

    .content-box {
        padding:0 15px;

        .text {
            a {
                color:$primary_color;
                font-weight: bold;
            }

            strong {
                color:$primary_color;
            }
        }
    }

    .text {
        margin-bottom:40px;
    }

    .galerie {
        a { margin-bottom:15px; }

        .galerie-image {
            background-repeat: no-repeat;
            background-position: center center;
            background-attachment: scroll;
            background-size: cover;
        }
    }

}


#list-documents {
    list-style-type: none;
    padding:0;
    li {
        margin-bottom:10px;

        a {
            text-decoration: none;
            color:$secondary_color;

            span {
                margin-right:5px;
            }

            &:hover {
                color:$primary_color;
            }
        }
    }
}

.documents {
    list-style-type: none;
    padding:0;
    li {
        margin-bottom:10px;

        a {
            text-decoration: none;
            color:$secondary_color;

            span {
                margin-right:5px;
            }

            &:hover {
                color:$primary_color;
            }
        }
    }
}

.btn-new {
    background-color: $primary_color;
    color:white;
    &:hover {
        color:white;
        background-color: $secondary_color;
    }
}

#signature {
    font-family: arial, sans-serif;
    display:none;
    position:fixed;
    bottom:10px;
    left:0;
    width:300px;
    img { height:40px; }
    a { color:white; font-size:10px; vertical-align: bottom; }
    .bottom-align-text {
        position: absolute;
        bottom: 2px;
        left: 0;
    }
}

.anim { -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }

/*
@media (min-width: 360px) and (max-width: 767px) and (max-height: 640px) {
    #home {
        .intro {
            a {
                font-size:30px;
                .intro-icone {
                    font-size:100px;
                    margin-bottom:10px;
                }
            }
        }
    }
}
*/


@media (min-width: 768px) {
    
    .p-b { padding-bottom:70px;}
    #home {height: 100vh;}
    body {margin-top:0px;}
    .container {
        width:100%;
    }
    
    section {padding-bottom: 50px!important;}

    .tile-text {font-size: 2.2vw; letter-spacing: 1px; line-height: 2.4vw;}
    
    .groupes, .privatisation, .contacts { 
        text-align: center;
        h2 {color : #F1CA13; font-size: 4vw; margin-top:60px; margin-bottom:40px;}
        p { color:#000; font-family: "Roboto"; font-size: 16px; line-height: 26px;}
    }
    
    .infos { 
        text-align: center;
        padding-top : 50px;
        h2 {color : #F1CA13; font-size: 4vw; margin-top:60px; margin-bottom:40px;}
        p { color:#000; font-family: "Roboto"; font-size: 16px; line-height: 26px; padding-bottom: 40px;}
        
    }
    
    #header {
        width:300px;

        .navbar-header {
            // border-right:4px solid $nav-link-hover-bg;

            .navbar-brand {
                height: 250px;
                padding:40px 50px;

                img {
                    height:auto;
                }
            }
        }
        
        .navbar-default { width:300px; }

        .sidebar {
            z-index: 1;
            position: absolute;
            width: 300px;
            height:100vh;
            margin-top: 0;
            #side-menu > li > a { 
                
                height: 80px;
                font-size: 24px; 
            }
        }

        .collapse-box {
           height:auto;
        }

        .nav-icone-fixed {
            top:50px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
        }

        #lang {
            top:50px;
            right:inherit;
            left:270px;
            height:30px;
            transition: left 0.35s ease-in-out;

            #collapse-langues {
                 height: 30px;
                 float:left;

                 > div { float:left; }
            }

            > a { float:left; }

            &:hover {
                left:300px;
                transition: left 0.35s ease-in-out;
            }
        }


        .menu-home-hide { display:block; }
        .sidebar-nav-cond { display:none; }
        .sidebar-nav-hide { display:none; }
        
        #side-menu > li {
             a { 
                font-size: 34px;
                .icon-header { display: inline-block; width: 60px; text-align: center; margin-right: 20px; padding: 10px;}
            }
            hr { margin-top:0; margin-bottom:0; border-top: solid 2px $tertiary_color;}
        }
    }

    #page-wrapper {
        position: relative;
        margin: 0 0 0 300px;
        padding:0;
    }

    #home {
        margin:0;
        padding-top:0;

        .intro {
            font-size: 6vw;
            color : #FFF;
            line-height: 7vw;
            padding-top : 50vh;
        }
    }

    

    #signature {
        display:block;
    }
}

@media only screen and (min-width: 996px) {
    .container {
        width:750px;
    }


    #content {

        
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        width:970px;
    }

    
}

#__bs_notify__ {
    height:10px !important;
    width:10px !important;
}