@font-face {
	font-family: 'Lilita One';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Lilita_One-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url(../fonts/Roboto-normal-300.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

